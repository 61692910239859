import * as React from 'react';

import NextError from 'next/error';

import ExceptionLogger from '~/backplane/makeExceptionLogger';

interface WebUiError extends Error {
  message: string;
}

type ExtraInfo = {
  url: string;
};

interface Props {
  error: WebUiError | null;
  statusCode?: number;
  extra?: ExtraInfo;
}

const defaultErrorCode = 400;

const Error = ({ statusCode, error, extra }: Props): JSX.Element => {
  const messagePrefix = statusCode ? `${statusCode} Error - ` : '';
  const messageBody = (error && error.message) || '';
  const message = `${messagePrefix}${messageBody}`;
  const args = extra ? { ...extra, error } : { error };
  ExceptionLogger.error(message, args);

  return (
    <NextError
      statusCode={statusCode || defaultErrorCode}
      error={error}
    />
  );
};

Error.getInitialProps = ({ res, err }: any): any => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : null;
  const extra = {
    url: res ? res.req?.path : null,
  };
  return { statusCode, error: err, extra };
};

export default Error;
