import exceptionLogger from '@circleci/exception-logger';

const isServer = typeof window === 'undefined';

export default exceptionLogger({
  checkIgnore: () => {
    return Boolean(
      !isServer &&
        window.navigator.userAgent &&
        (window.navigator.userAgent.indexOf('MSIE') !== -1 ||
          window.navigator.userAgent.indexOf('Trident') !== -1),
    );
  },
});
